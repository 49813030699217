import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ButtonComponent, HeadingComponent, TextFieldComponent, SpinnerComponent } from '../Reusable/';
import { PdfChecklistModel } from './PdfChecklistModel';
import * as Mapper from '../../helper/Mappers';
import * as PDF from './TabPdfGeneration';
import { Document, pdf } from '@react-pdf/renderer';
import _ from 'lodash';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { ExecutorStore } from '../../store/AccountStore';
import { getExecutorLogo } from '../../helper/Pdf';

export default function PdfChecklistDialog(props) {
    const { open, setOpen, checklist } = props;

    const [executorSettings, setExecutorSettings] = useState({});

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            setExecutorSettings(executorSettings);
        })
        return execSub.unsubscribe();
    })

    const { t } = useTranslation();
    const [isGeneratePressed, setIsGeneratePressed] = useState(false);
    const [mainModel, setMainModel] = useState(PdfChecklistModel(t));

    useEffect(() => {
        let newModel = _.cloneDeep(PdfChecklistModel(t));

        if (checklist.compressedAirGeneration === false) {
            newModel.compressedAirGeneration.value = 0;
        }
        if (checklist.airQuality === false) {
            newModel.installedDryers.value = 0;
            newModel.airQualityMeasurement.value = 0;
        }
        if (checklist.airNetwork === false) {
            newModel.distributionLine.value = 0;
            newModel.airReceivers.value = 0;
            newModel.pressureDropAnalysis.value = 0;
        }
        if (checklist.macac === false) {
            newModel.macac.value = 0;
        }
        if (checklist.ldar === false) {
            newModel.leakageDetection.value = 0;
        }

        setMainModel(newModel);
    }, [open])

    const updateModel = (property, value) => {
        setIsGeneratePressed(false);
        let updatedModel = Mapper.updateModel(property, value, mainModel);
        setMainModel({ ...updatedModel });
    }

    const validateForm = () => {
        let isValid = true;
        Object.values(mainModel).forEach(field => field.validators.forEach(v => {
            if (v(field.value) === false) isValid = false;
        }));
        return isValid;
    }

    const getTableArrayCount = (count, maxCount) => {
        let newArray = [];
        if (count <= 0) return newArray;
        for (var i = 0; i < Math.floor(count / maxCount); i++) {
            newArray.push(maxCount);
        }
        if (count % maxCount > 0) newArray.push(count % maxCount);
        return newArray;
    }

    const maxCountValue = 20;

    const pdfDocument = (pageSize, logo) => (
        <Document>
            <PDF.GeneralInformationPdf pageSize={pageSize} logo={logo} />
            <PDF.PreAuditPdf pageSize={pageSize} logo={logo} />
            {Array.apply(null, { length: mainModel.compressedAirGeneration.value }).map((e, i) => <PDF.CompressorPdf key={'cag' + i} pageSize={pageSize} logo={logo} />)}
            {getTableArrayCount(mainModel.distributionLine.value, maxCountValue).map((e, i) => <PDF.AirNetworkDistributionLinePdf key={'dl' + i} count={e} offset={maxCountValue * i} pageSize={pageSize} logo={logo} />)}
            {getTableArrayCount(mainModel.airReceivers.value, maxCountValue).map((e, i) => <PDF.AirNetworkAirReceiverPdf key={'receivers' + i} count={e} offset={maxCountValue * i} pageSize={pageSize} logo={logo} />)}
            {Array.apply(null, { length: mainModel.pressureDropAnalysis.value }).map((e, i) => <PDF.AirNetworkPressureDropPdf key={'pressuredroppdf' + i} pageSize={pageSize} logo={logo} />)}
            {getTableArrayCount(mainModel.installedDryers.value, maxCountValue).map((e, i) => <PDF.AirQualityDryersPdf key={'dryers' + i} count={e} offset={maxCountValue * i} pageSize={pageSize} logo={logo} />)}
            {Array.apply(null, { length: mainModel.airQualityMeasurement.value }).map((e, i) => <PDF.AirQualityMeasurmentPdf key={'AirQualityMeasurement' + i} pageSize={pageSize} logo={logo} />)}
            {Array.apply(null, { length: mainModel.macac.value }).map((e, i) => <PDF.MacacPdf key={'macacpdf' + i} pageSize={pageSize} logo={logo} />)}
            {Array.apply(null, { length: mainModel.leakageDetection.value }).map((e, i) => <PDF.LeakageDetectionPdf key={'macacpdf' + i} pageSize={pageSize} logo={logo} />)}
            {getTableArrayCount(mainModel.replacementParts.value, maxCountValue).map((e, i) => <PDF.ReplacementPartsPdf key={'parts' + i} count={e} offset={maxCountValue * i} pageSize={pageSize} logo={logo} />)}
        </Document>
    );

    const generatePDFDocument = async () => {
        try {
            let logo = await getExecutorLogo();
            let pageSize = executorSettings?.printSize?.value ?? "A4";
            const blobPdf = await pdf(pdfDocument(pageSize, logo));
            blobPdf.updateContainer(pdfDocument(pageSize, logo));
            let blob = await blobPdf.toBlob();
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "FESS_template.pdf");
            document.body.appendChild(link);
            link.click();
            setIsGeneratePressed(false);
            OpenGlobalSnackbar("success", t('PdfGeneratedSuccessfully'));
        } catch {
            OpenGlobalSnackbar("danger", t('ErrorGeneratingPdf'));
            setIsGeneratePressed(false);
        }
    };

    const initiateGenerate = async () => {
        if (validateForm() === true) {
            setIsGeneratePressed(true);
            generatePDFDocument();
        }
        else OpenGlobalSnackbar("danger", t('ModelInvalidError'));
    }

    return (
        <Dialog
            aria-labelledby="transition-modal-title"
            className='pdf-checklist-dialog'
            open={open}
            onClose={() => { setIsGeneratePressed(false); setOpen(false); }}
            closeAfterTransition
        >
            <SpinnerComponent open={isGeneratePressed} />
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <h3 style={{ margin: "0px" }}>{t('PrintCheckListHeading')}</h3>
                    </Grid>
                    <Grid item>
                        <IconButton
                            color="inherit"
                            onClick={() => { setIsGeneratePressed(false); setOpen(false); }}
                            size="large"
                        >
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="column">
                    <Grid item>
                        <Grid container justifyContent="center" style={{ marginBottom: "30px" }}>
                            <HeadingComponent value={t('SetNumberOfCopies')} size="h6" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" spacing={3}>
                            {checklist.compressedAirGeneration &&
                                <Grid item xs={12} md={6}>
                                    <TextFieldComponent t={t} model={mainModel.compressedAirGeneration} onChange={e => {
                                        updateModel("compressedAirGeneration", e.target.value)
                                    }} />
                                </Grid>
                            }
                            {checklist.airQuality &&
                                <React.Fragment>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldComponent t={t} model={mainModel.installedDryers} onChange={e => {
                                            updateModel("installedDryers", e.target.value)
                                        }} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldComponent t={t} model={mainModel.airQualityMeasurement} onChange={e => {
                                            updateModel("airQualityMeasurement", e.target.value)
                                        }} />
                                    </Grid>
                                </React.Fragment>
                            }
                            {checklist.airNetwork &&
                                <React.Fragment>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldComponent t={t} model={mainModel.distributionLine} onChange={e => {
                                            updateModel("distributionLine", e.target.value)
                                        }} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldComponent t={t} model={mainModel.airReceivers} onChange={e => {
                                            updateModel("airReceivers", e.target.value)
                                        }} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldComponent t={t} model={mainModel.pressureDropAnalysis} onChange={e => {
                                            updateModel("pressureDropAnalysis", e.target.value)
                                        }} />
                                    </Grid>
                                </React.Fragment>
                            }
                            {checklist.macac &&
                                <Grid item xs={12} md={6}>
                                    <TextFieldComponent t={t} model={mainModel.macac} onChange={e => {
                                        updateModel("macac", e.target.value)
                                    }} />
                                </Grid>
                            }
                            {checklist.ldar &&
                                <Grid item xs={12} md={6}>
                                    <TextFieldComponent t={t} model={mainModel.leakageDetection} onChange={e => {
                                        updateModel("leakageDetection", e.target.value)
                                    }} />
                                </Grid>
                            }
                            <Grid item xs={12} md={6}>
                                <TextFieldComponent t={t} model={mainModel.replacementParts} onChange={e => {
                                    updateModel("replacementParts", e.target.value)
                                }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <ButtonComponent value={t('Cancel')} color="secondary" onChange={() => { setIsGeneratePressed(false); setOpen(false) }}></ButtonComponent>
                                    </Grid>
                                    <Grid item>
                                        <ButtonComponent value={t('Print')} color="primary" onChange={() => initiateGenerate()} id="print-template"></ButtonComponent>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}