import React, { useEffect, useState } from 'react'
import { Grid, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import "./ContentNavWrapper.css"

export default function ContentNavWrapper({ children, ...props }) {
    const { showContent } = props;
    const { t } = useTranslation();

    const [activeBtn, setActiveBtn] = useState([0]);

    useEffect(() => {
        document.addEventListener('scroll', handleHighlight);
        return () => {
            document.removeEventListener('scroll', handleHighlight);
        }
    }, [])

    const TabHeadings = () => {
        var path = window.location.href.split("/");
        var checkpath = path[7]?.indexOf("#");
        if (path[8]) {
            switch (path[8]) {
                case "AddEditCompressor":
                    return ["#IndividualCompressorInformation", "#Location", "#Details", "#WorkloadAirFlow", "#ReplacementParts"]
                case "IndividualDryer":
                    return ["#IndividualDryerInformation", "#Location", "#Details", "#AssignedCompressors", "#ReplacementParts"]
                case "AirQualityMeasurement":
                    return ["#AirQualityMeasurement", "#Location", "#Particle", "#Water", "#Oil", "#ReplacementParts"]
                case "DistributionLine":
                    return ["#DistributionLine", "#ReplacementParts"]
                case "AirReceiver":
                    return ["#AirReceiver", "#Location", "#ReplacementParts"]
                case "PressureDropAnalysis":
                    return ["#PressureDropAnalysis", "#Location", "#PressureLevelAnalysis", "#SavingsReducingThePressureLevel", "#ReplacementParts"]
                case "Application":
                    return ["#Application", "#Location", "#Savings", "#TotalFutureInvestment", "#AnnualSavingsAfterTaxes", "#ReplacementParts"]
                case "Leakage":
                    return ["#LeakageIdentification", "#LeakageRepair", "#Location", "#Details", "#ReplacementParts"]
                default:
                    return []
            }
        }
        else {
            switch (checkpath && checkpath != -1 ? path[7].slice(0, checkpath) : path[7]) {
                case "PS":
                    return ["#ProjectSettings", "#CustomerAccess", "#TravelInformation", "#ProjectSpecificSettings"]
                case "GI":
                    return ["#GeneralInformation", "#EnergyCost", "#PercentegeofCompressedAir"]
                case "CAG":
                    if (props?.menuData?.isAnalysisOfTheCompressedAirGenerationSelected)
                        return ["#IndividualCompressorDetails", "#CompressorCapacityInformation", "#MeasurementOfTheCompressedAirGeneration", "#WorkloadCompressorsInformation", "#StaticConsumptionHeader", "#CompressorUnloadTime", "#FlowRateInformation", "#YearlyConsumptionInformation", "#SpecificPowerOfSystem", "#CostsInformation", "#AirConsumptionProfile"]
                    else
                        return ["#IndividualCompressorDetails", "#CompressorCapacityInformation", "#MeasurementOfTheCompressedAirGeneration", "#StaticConsumptionHeader", "#CompressorUnloadTime", "#CostsInformation", "#AirConsumptionProfile"]
                case "AQ":
                    return ["#InstalledDryers", "#AirQualityMeasurement"]
                case "AN":
                    return ["#DistributionLine", "#AirReceivers", "#StorageAirCapacity", "#PressureDropAnalysis"]
                case "MACAC":
                    return ["#Application", "#PaybackTime"]
                case "LA":
                    return ["#CostsPerUnit", "#LeakageLevelCosts", "#LeakageDetectionAndRepairCosts", "#AnnualSavingsAfterTaxes", "#PaybackTime"]
                case "LDAR":
                    return ["#CostsAndSavingofTheLeakages", "#QuantityOfDetectedLeakages", "#LeakageDetection", "#ReplacementParts", "#Charts"]
                case "MassEditLDAR":
                    return []
                case "CS":
                    return ["#SavingsEliminatingTheLeakagesTable", "#SavingsShuttingOffMainAirSupplyAtNonProductionTimeTable", "#SavingsReducingThePressureDropTable", "#SavingsReducingThePressureLevelOfTheSystemTable", "#SavingsOptimizingTheAirConsumptionAtSingleApplicationsTable", "#CumulativeHighestSavingsPerYearTable"]
                default:
                    return ["#ProjectSettings", "#CustomerAccess", "#TravelInformation", "#ProjectSpecificSettings"]
            }
        }
    }

    const ScrolltoHeading = (value) => {
        var element = document.getElementById(value);
        if (element != null) {
            window.scrollTo({
                top: element.offsetTop - 50,
                behavior: 'smooth'
            })
        }
    }

    const handleHighlight = () => {
        var temparr = [];
        var isVisible = false;
        var viewIsbottomOfDocument = (window.innerHeight + window.scrollY) >= document.body.offsetHeight - 100
        if (TabHeadings()) {
            TabHeadings().map((i, index) => {
                var element = document.getElementById(i);
                if (element != null) {
                    var elementDimensionsBottom = element.offsetTop + element.offsetHeight;
                    var elementDimensionsTop = element.offsetTop - 100;
                    if (viewIsbottomOfDocument) {
                        var isElementInViewPort = element.getBoundingClientRect();
                        if (
                            isElementInViewPort.top >= 0 &&
                            isElementInViewPort.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                            && isElementInViewPort
                        ) {
                            isVisible = true;
                            temparr.push(index)
                        }
                    }
                    else if (window.scrollY > elementDimensionsTop && window.scrollY < elementDimensionsBottom) {
                        setActiveBtn([index])
                    }
                }
            })
        }
        if (isVisible) {
            setActiveBtn(temparr)
        }
    }

    return (
        <div>
            {TabHeadings()?.length > 0 ? <div>
                <Grid container className="ContentWrapper">
                    {!showContent ? <Grid sm={2} item className="ContentWrapper-navigation">
                        <Typography variant="h6">
                            {t("Content")}
                        </Typography>
                        <Grid item className="nav-border">
                            {TabHeadings().map((i, index) => <Grid item key={index}><Button className={activeBtn.some(i => index == i) ? "active-heading" : ""} onClick={() => { ScrolltoHeading(i) }}>{t(i.slice(1))}</Button></Grid>)}
                        </Grid>
                    </Grid>
                        : <div></div>}
                    <Grid sm={!showContent ? 10 : 12} item className={!showContent ? "ContentWrapper-content" : ""}>
                        {children}
                    </Grid>
                </Grid>
            </div> : <div>{children}</div>}
        </div>
    )
}
