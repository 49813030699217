import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { SpinnerComponent } from '../Reusable/';
import { Button } from "@mui/material";
import { InsertDriveFile } from '@mui/icons-material';
import { Document, pdf } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { API } from '../../helper/ApiHelper';
import * as PDF from './PdfContent';
import ChartDialog from './PdfContent/ChartDialog';
import ReportCategoryDialog from './ReportCategoryDialog';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { ExecutorStore, CountryListStore } from '../../store';
import { asyncImage, getExecutorLogo, getChartDomAsync } from '../../helper/Pdf';
import { createPDF, pdfArrayToBlob, mergePDF } from "pdf-actions";

export default function FinalizeReportPdf() {
    const getProjectUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "project-management/project/";
    const getDropdownValuesUrl = process.env.REACT_APP_DEVFESSINQUIRYSERVICES_BASE + "inquiry-management/drop-downs";
    const getProjectImagesUrl = process.env.REACT_APP_DEVFESSIMAGESERVICES_BASE + "blob/project";
    const getReplacementPartsUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "replacement-part";

    const { t } = useTranslation();
    const { id, lng, country } = useParams();
    const [mainModel, setMainModel] = useState({});
    const [chartDialogOpen, setChartDialogOpen] = useState(false);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
    const [isGeneratePressed, setIsGeneratePressed] = useState(false);
    const [isProjectPneumaticsOnly, setIsProjectPneumaticsOnly] = useState(false);
    const [executorSettings, setExecutorSettings] = useState({});
    const [dropdownValues, setDropdownValues] = useState({});
    const [charts, setCharts] = useState({});
    const [imageArray, setImageArray] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [executorList, setExecutorList] = useState([]);
    const [pdfFiles, setPdfFiles] = useState([]);

    useEffect(() => {
        CountryListStore.initCountryList(lng);
        let countryListSub = CountryListStore.countryList.subscribe(list => {
            setCountryList(list);
        })
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            setExecutorSettings(executorSettings);
        })
        let execSub1 = ExecutorStore.executorList.subscribe(list => {
            setExecutorList(list);
        })
        return () => {
            execSub.unsubscribe();
            execSub1.unsubscribe();
            countryListSub.unsubscribe();
        }
    }, []);

    const pdfDocument = (tocValues) =>
    (
        <Document>
            <PDF.CoverPdf
                logo={mainModel.executorLogo}
                data={mainModel.project}
                pageSize={mainModel.pageSize}
                isProjectPneumaticsOnly={isProjectPneumaticsOnly}
                countryList={countryList}
            />
            <PDF.TableOfContentPdf
                tocValues={tocValues}
                data={mainModel.project}
                pageSize={mainModel.pageSize}
                pdfFiles={pdfFiles}
            />
            {tocValues.categories.isExecutiveSummarySelected &&
                <PDF.ExecutiveSummaryPdf
                    tocValues={tocValues}
                    data={mainModel.project}
                    pageSize={mainModel.pageSize}
                    newImageArray={imageArray}
                />
            }
            {tocValues.categories.isGeneralInformationSelected &&
                <PDF.GeneralInformationPdf
                    tocValues={tocValues}
                    data={mainModel.project}
                    calcs={mainModel.giCalcResult}
                    pageSize={mainModel.pageSize}
                    country={country}
                />
            }
            {tocValues.categories.isAnalysisOfTheCompressedAirGenerationSelected &&
                <PDF.CompressedAirGenerationPdf
                    dropdownValues={dropdownValues}
                    chart={charts.cag}
                    tocValues={tocValues}
                    data={mainModel.project.compressedAirGeneration}
                    projectData={mainModel.project}
                    currency={mainModel.project.currency}
                    compressors={mainModel.project.compressedAirGeneration.compressorList}
                    calcs={mainModel.cagCalculationResult}
                    pageSize={mainModel.pageSize}
                    country={country}
                />
            }
            {tocValues.categories.isCompressedAirQualityAnalysisSelected &&
                <PDF.AirQualityPdf
                    tocValues={tocValues}
                    data={mainModel.project}
                    pageSize={mainModel.pageSize}
                    calcs={mainModel.airQualityCalcResults}
                    country={country}
                />
            }
            {tocValues.categories.isAirNetworkAnalysisSelected &&
                <PDF.AirNetworkPdf
                    tocValues={tocValues}
                    data={mainModel.project.airNetworkAnalysis}
                    projectData={mainModel.project}
                    calcs={mainModel.airNetworkCalcResult}
                    pageSize={mainModel.pageSize}
                    country={country}
                />
            }
            {tocValues.categories.isMachineAnalysisForEnergyEfficiencySelected &&
                <PDF.MachineAnalysisPdf
                    tocValues={tocValues}
                    projectData={mainModel.project}
                    calcs={mainModel.macacCalcResults}
                    pageSize={mainModel.pageSize}
                    newImageArray={imageArray}
                    country={country}
                />
            }
            {tocValues.categories.isLeakageAnalysisSelected &&
                <PDF.LeakageAnalysisPdf
                    tocValues={tocValues}
                    chart={charts.leakagePayback}
                    data={mainModel.project}
                    calcs={mainModel.leakageAnalysisCalcResult}
                    pageSize={mainModel.pageSize}
                    country={country}
                />
            }
            {tocValues.categories.isLeakageDetectionAndDocumentationSelected &&
                <PDF.LeakageDetectionAndRepairPdf
                    tocValues={tocValues}
                    data={mainModel.project.projectLDAR}
                    projectData={mainModel.project}
                    currency={mainModel.project.currency}
                    calcs={mainModel.ldarCalcResult}
                    pageSize={mainModel.pageSize}
                    chart={charts}
                    country={country}
                />
            }
            {tocValues.categories.isCostSavingsSelected &&
                <PDF.CostSavingsPdf
                    tocValues={tocValues}
                    data={mainModel.costSavingsResult}
                    projectData={mainModel.project}
                    chart={charts.costSaving}
                    currency={mainModel.project.currency}
                    pageSize={mainModel.pageSize}
                    country={country}
                />
            }
            {tocValues.categories.isReplacementPartsSelected &&
                <PDF.ReplacementPartsPdf
                    data={mainModel.replacementParts}
                    projectData={mainModel.project}
                    tocValues={tocValues}
                    pageSize={mainModel.pageSize}
                    country={country}
                />
            }
            {(tocValues.categories.isPdfAttachmentsSelected && pdfFiles && pdfFiles.length > 0) &&
                <PDF.AttachmentsPdf
                    tocValues={tocValues}
                    projectData={mainModel.project}
                    pageSize={mainModel.pageSize}
                />
            }
        </Document>
    );

    const generateCharts = async () => {
        try {
            setChartDialogOpen(true);
            await new Promise((resolve) => { setTimeout(() => resolve(), 5000) }) // wait for chart dialog to draw charts
            let cagDom = await getChartDomAsync("air-profile-chart", 100, 100);
            let costSavingsChartDom = await getChartDomAsync("savings-bar-chart", 100, 100);
            let leakagePaybackDom = await getChartDomAsync("leakage-analysis-payback-chart", 100, 100);
            let ldarDetectedLeakagesDom = await getChartDomAsync("ldar-detected-leakages-chart", 100, 100);
            let ldarRepairProcessDom = await getChartDomAsync("ldar-repair-process-chart", 100, 100);
            let ldarCostSavingsDom = await getChartDomAsync("ldar-costsandsavings-chart", 100, 100);
            let ldarCO2Dom = await getChartDomAsync("ldar-co2-chart", 100, 100);
            charts["cag"] = await domtoimage.toPng(cagDom);
            charts["costSaving"] = await domtoimage.toPng(costSavingsChartDom);
            charts["leakagePayback"] = await domtoimage.toPng(leakagePaybackDom);
            charts["ldarDetectedLeakages"] = await domtoimage.toPng(ldarDetectedLeakagesDom);
            charts["ldarRepairProcess"] = await domtoimage.toPng(ldarRepairProcessDom);
            charts["ldarCostSavings"] = await domtoimage.toPng(ldarCostSavingsDom);
            charts["ldarCO2"] = await domtoimage.toPng(ldarCO2Dom);
            setCharts({ ...charts });
        } finally {
            setChartDialogOpen(false);
        }
    }

    const generatePDFDocument = async () => {
        if (process.env.REACT_APP_IS_TRAINING?.toLowerCase() === "true") {
            OpenGlobalSnackbar("danger", t('ReportTrainingSystemWarning'));
            return;
        }

        //known issues
        //wrapping doesnt work on certain characters: numbers, space, dot, "a"

        try {
            setIsGeneratePressed(true);
            CountryListStore.initCountryList(lng);
            let dataPromise = API.get(getProjectUrl + id, { isBackground: true });
            let dropdownValuesPromise = API.get(getDropdownValuesUrl, { isBackground: true });
            let replacementPartsPromise = API.get(`${getReplacementPartsUrl}/cart/${id}`, { isBackground: true });
            let filesListPromise = API.get(`${getProjectImagesUrl}/${id}/files`, { isBackground: true });

            let data = await dataPromise;
            setDropdownValues(await dropdownValuesPromise);
            data.replacementParts = await replacementPartsPromise;
            var files = await filesListPromise;
            data.project.imageList = files.filter((x) => x.width > 0);
            setPdfFiles(files.filter((x) => getFileExtension(x.url) === "pdf"));
            setImageArray(await asyncImage(data.project.imageList));
            data.pageSize = executorSettings?.printSize?.value ?? "A4";

            let executorId = data.project.projectSettings.subsidiaryExecutorId;
            data.executorLogo = await getExecutorLogo(executorList.find((x) => x.id == executorId)?.id);
            setMainModel(data);
            setIsProjectPneumaticsOnly(checkIsProjectPneumaticsOnly(data.project));
            await generateCharts();
            setCategoryDialogOpen(true);
        } catch {
            OpenGlobalSnackbar("danger", t('ErrorGeneratingPdf'));
        } finally {
            setIsGeneratePressed(false);
        }
    };

    const getFileExtension = (uri) => {
        var filename = uri.substring(uri.lastIndexOf('/') + 1);
        var extension = filename.substring(filename.lastIndexOf('.') + 1, filename.indexOf('?'));
        return extension.toLowerCase();
    }

    const validateProjectForPdfGeneration = async (tocValues) => {
        setIsGeneratePressed(true);
        try {
            await API.post(getProjectUrl + id + "/validateproject");
            generatePdfFile(tocValues);
        } catch {
            setIsGeneratePressed(false);
        }
    }

    const generatePdfFile = async (tocValues) => {
        try {
            //first run. needed for table of content tocValues
            let blobPdf = await pdf(pdfDocument(tocValues));
            blobPdf.updateContainer(pdfDocument(tocValues));
            await blobPdf.toBlob();
            //second run. getting final pdf
            blobPdf = await pdf(pdfDocument(tocValues));
            blobPdf.updateContainer(pdfDocument(tocValues));
            var pdfBlob = await blobPdf.toBlob();
            if (tocValues.categories.isPdfAttachmentsSelected && pdfFiles && pdfFiles.length > 0){
                pdfBlob = await mergePDFs(pdfBlob);
            }
            saveAs(pdfBlob, "FESS_final_report.pdf");
            OpenGlobalSnackbar("success", t('PdfGeneratedSuccessfully'));
        } catch {
            OpenGlobalSnackbar("danger", t('ErrorGeneratingPdf'));
        } finally {
            setIsGeneratePressed(false);
        }
    }
    
    const mergePDFs = async (pdfBlob) => {
        var pdfDoc = await createPDF.PDFDocumentFromFile(pdfBlob)
        var pdfDocs = [];
        pdfDocs.push(pdfDoc);
        pdfFiles.sort((a, b) => a.tabIndex - b.tabIndex);
        for (let i = 0; i < pdfFiles.length; i++) {
            let file = await getFileFromURI(pdfFiles[i].url);
            pdfDoc = await createPDF.PDFDocumentFromFile(file);
            pdfDocs.push(pdfDoc);
        }
        let mergedPdfFile = await (await mergePDF(pdfDocs)).save();
        return pdfArrayToBlob(mergedPdfFile);
    }

    const getFileFromURI = async (uri) => {
        const response = await fetch(uri);
        const fileBlob = await response.blob();
        return fileBlob;
    }

    const checkIsProjectPneumaticsOnly = project => project.isLeakageDetectionAndDocumentationSelected
        && !project.isAnalysisOfTheCompressedAirGenerationSelected
        && !project.isCompressedAirQualityAnalysisSelected
        && !project.isAirNetworkAnalysisSelected
        && !project.isMachineAnalysisForEnergyEfficiencySelected;

    return (
        <React.Fragment>
            <SpinnerComponent open={isGeneratePressed} />
            <ChartDialog open={chartDialogOpen} data={mainModel} />
            <ReportCategoryDialog
                open={categoryDialogOpen}
                setOpen={setCategoryDialogOpen}
                data={mainModel}
                generate={validateProjectForPdfGeneration}
                isProjectPneumaticsOnly={isProjectPneumaticsOnly}
                pdfFiles={pdfFiles}
            />
            <Button
                onClick={generatePDFDocument}
                id="Menu-Finalize-Report"
                className="projectMenu-button"
            >
                <InsertDriveFile />
                {t("GenerateReport")}
            </Button>
        </React.Fragment>
    )
}
