import React from "react";
import { ExcelFile, ExcelSheet } from "react-xlsx-wrapper";
import { useTranslation } from 'react-i18next';
import { ButtonComponent } from '../../Reusable';
import { ILeakageExcelRow } from "../../Types/Types";
import * as Fmt from '../../../helper/Formatters';
import * as borders from '../../../helper/ExcelHelpers/Constants';
import { BorderStyleRules } from "../../../helper/ExcelHelpers/Constants";
// @ts-expect-error not converted yet
import { GenerateTopHeader, GenerateRows } from '../../../helper/ExcelHelpers/ExcelHelper';
import * as DateHelper from '../../../helper/DateHelper';
import _ from 'lodash';

const generateStyleRules = (): Record<number, BorderStyleRules> => {
    const baseRules: BorderStyleRules = {
        first: borders.topThick,
        middle: borders.basicBorder,
        last: borders.bottomThick,
    };
    const rules: Record<number, BorderStyleRules> = {};
    for (let i = 0; i <= 32; i++) {
        rules[i] = { ...baseRules };
        if ([3, 6, 14, 21, 26, 31, 32].includes(i)) {
            rules[i].first = borders.topRightThick;
            rules[i].middle = borders.rightThick;
            rules[i].last = borders.bottomRightThick;
        }
        if ([9, 10, 11, 14].includes(i)) {
            rules[i].numFmt = "0.00";
        }
    }
    return rules;
};

interface IProps {
    data: ILeakageExcelRow[];
    element?: React.JSX.Element;
}

export const LeakageToExcel = (props: IProps) => {
    const { data, element } = props;
    const { t } = useTranslation();

    const generateDataSet = () => {
        return [
            GenerateTopHeader("ReportLeakageDetection", t),
            {
                ySteps: 2,
                columns: [
                    { title: "" },
                    { title: t("LeakageIdentification"), },
                    { title: "" },
                    { title: "", style: { border: borders.leftThickOnly, }, },
                    { title: "" },
                    { title: t("Location") },
                    { title: "", style: { border: borders.leftThickOnly, }, },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    { title: t("LeakageDetails") },
                    { title: "" },
                    { title: "" },
                    { title: "", style: { border: borders.leftThickOnly, }, },
                    { title: "" },
                    { title: "" },
                    { title: "" },
                    { title: t("LeakageRepair") },
                    { title: "" },
                    { title: "" },
                    { title: "", style: { border: borders.leftThickOnly, }, },
                    { title: "" },
                    { title: "" },
                    { title: t("DefectedDevice") },
                    { title: "" },
                    { title: "", style: { border: borders.leftThickOnly, }, },
                    { title: "" },
                    { title: "" },
                    { title: t("Replacement") },
                    { title: "" },
                    { title: "", style: { border: borders.leftThickOnly, }, },
                    { title: "", style: { border: borders.leftThickOnly, }, },
                ],
                data: [],
            },
            {
                columns: [
                    {
                        title: t("LeakageNr"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("MeasurementId"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("EntryDate"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("DetectedBy"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.rightThick, },
                    },
                    {
                        title: t("Building"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("Department"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("Machine"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.rightThick, },
                    },
                    {
                        title: t("OptimizationInsteadOfLeakage"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("LeakageInputDevice"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("LeakageInputValue"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("LeakageFlowConverted") + Fmt.getUnitInBracket("volumeFlowRate", t),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("VolumePerYear") + Fmt.getUnitInBracket("cubicMeterOfGas", t),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("EnergyConsumptionPerYear") + Fmt.getUnitInBracket("powerPerHour", t),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("CO2EmissionsPerYear") + Fmt.getUnitInBracket("co2Wheight", t),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("CostPerYear") + Fmt.getCurrency(),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.rightThick, },
                    },
                    {
                        title: t("LeakageAction"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("EstimatedRepairTime"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("LeakageRepearableProdTime"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("LeakageRepaired"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("RepairedBy"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("RepairDate"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("RepairTime"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.rightThick, },
                    },
                    {
                        title: t("UniquePartCode"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("PartNumber"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("TypeCode"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("Component"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("Manufacturer"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.rightThick, },
                    },
                    {
                        title: t("NewPartNumber"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("NewTypeCode"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, }
                    },
                    {
                        title: t("NewManufacturer"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("Quantity"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.basicBorder, },
                    },
                    {
                        title: t("OrderSparePart"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.rightThick, }
                    },
                    {
                        title: t("LeakageComment"),
                        width: { wch: borders.titleWidth },
                        style: { border: borders.rightThick, },
                    },
                ],
                data: GenerateRows(
                    _.cloneDeep(data).map(entry => ({
                        //Leakage detection / pneumatic inspection:
                        leackageNumber: entry.leackageNumber,
                        measurementId: entry.measurementId,
                        entryDate: DateHelper.FormatDate(entry.entryDate),
                        detectedBy: entry.detectedBy,
                        //Location:
                        building: entry.building,
                        department: entry.department,
                        machine: entry.machine,
                        //Leakage details:
                        leakageOptimization: entry.leakageOptimization,
                        leakageInputDevice: t(entry.leakageInputDevice),
                        leakageInputValue: entry.leakageInputValue,
                        airFlow: entry.airFlow,
                        volumePerYear: entry.volumePerYear,
                        energyPerYear: entry.energyPerYear,
                        emissionsPerYear: entry.emissionsPerYear,
                        costPerYear: entry.costPerYear,
                        //Has the repair been performed:
                        leakageAction: t(entry.leakageAction),
                        repairTimeEstimated: entry.repairTimeEstimated,
                        leakageRepearableProdTime: entry.leakageRepearableProdTime,
                        leakageRepaired: entry.leakageRepaired,
                        repairedBy: entry.repairedBy,
                        repairDate: entry.leakageRepaired === 'Yes' ? DateHelper.FormatDate(entry.repairDate) : "",
                        leakageRepairTimeRepairedInput: entry.leakageRepairTimeRepairedInput,
                        //Defect part:
                        replacementId: entry.replacementId,
                        articleNrDefected: entry.articleNrDefected,
                        typeCodeDefected: entry.typeCodeDefected,
                        componentDefected: t(entry.componentDefected),
                        manufacturerDefected: entry.manufacturerDefected,
                        //Replacement:
                        articleNrReplacement: entry.articleNrReplacement,
                        typeCodeReplacement: entry.typeCodeReplacement,
                        manufacturerReplacement: entry.manufacturerReplacement,
                        quantity: entry.quantity,
                        orderSparePart: entry.orderSparePart ? t("Yes") : t("No"),
                        //Leakage Comment:
                        leakageComment: entry.leakageComment,
                    })),
                    generateStyleRules()
                ),
            },
        ];
    };

    return (
        <ExcelFile
            filename="Leakage_list"
            element={element ?? <ButtonComponent id="btn_LDAR-Excel-leakages" value={t("LeakagesExcel")} color="primary"></ButtonComponent>}
        >
            <ExcelSheet dataSet={generateDataSet()} name="Leakages List" />
        </ExcelFile>
    );
};
