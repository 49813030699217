import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Dialog, DialogTitle, IconButton, DialogContent, Typography, Button } from '@mui/material';
import { TextFieldComponent, ButtonComponent, SpinnerComponent, HeadingComponent } from '../Reusable';
import ProjectQrCodePrint from '../ProjectQrCodesAndTemplates/ProjectQrCodePrint/ProjectQrCodePrint';
import { checkNumberMoreZero } from "../../helper/Validator";
import { API } from '../../helper/ApiHelper';
import QrCodes from './QrCodes';
import { Document, pdf } from '@react-pdf/renderer';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { Close } from '@mui/icons-material';
import { getExecutorLogo } from '../../helper/Pdf';
import QRCode from 'qrcode';

export default function QrCodeGeneration(props) {
    const { showPrintExistingCodes } = props;
    const [open, setOpen] = useState(false)
    const [isGeneratePressed, setIsGeneratePressed] = useState(false);
    const [printQrCodesOpen, setPrintQrCodesOpen] = useState(false);
    const { t } = useTranslation();
    const [element, setElement] = useState({ name: "Quantity", label: t("Quantity"), value: "", idkey: "QrQuantity", isValid: true, validationError: t("ValidNumberError"), validators: [checkNumberMoreZero] });

    const updateModel = (value) => {
        element.value = value;
        element.isValid = true;
        element.validators.map((v) => { if (v(element.value) === false) element.isValid = false; });
        setElement({ ...element });
    }
    const qcUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "qr-code/reserve";

    const actionGenerate = async () => {
        element.validators.map((v) => { if (v(element.value) === false) element.isValid = false; });
        if (element.isValid) {
            if (element.value <= 1000) {
                setIsGeneratePressed(true);
                await generatePDFDocument();
                setIsGeneratePressed(false);
                setOpen(false);
            }
            else OpenGlobalSnackbar("danger", t('ValueOverTheLimmit'));
        }
        else {
            setElement({ ...element });
            OpenGlobalSnackbar("danger", t('EnterValue'));
        }
    };

    const generateQrCodes = async () => {
        let resp = await API.post(qcUrl + '/' + element.value);
        let start = resp.start;
        let end = resp.end;
        let qrCodeArray = [];
        for (var i = start; i <= end; i++) {
            let qcImageData = await QRCode.toDataURL(i.toString());
            qrCodeArray.push({
                id: i,
                qrCodeDataUri: qcImageData
            });
        }
        return qrCodeArray;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const generatePDFDocument = async () => {
        try {
            let qrCodes = await generateQrCodes();
            let logo = await getExecutorLogo();
            const blobPdf = await pdf(qrDocument(logo, qrCodes));
            blobPdf.updateContainer(qrDocument(logo, qrCodes));
            let blob = await blobPdf.toBlob();
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "FESS_QRCODES.pdf");
            document.body.appendChild(link);
            link.click();
            element.value = "";
            element.isValid = true;
            await setElement({ ...element });

        } catch {
            OpenGlobalSnackbar("danger", t('ErrorGeneratingPdf'));
        } finally {
            OpenGlobalSnackbar("success", t('PdfGeneratedSuccessfully'));
        }
    };

    const qrDocument = (logo, qrCodes) =>
    (
        <Document>
            <QrCodes
                logo={logo}
                QrCodes={qrCodes}
                size={props?.executorSettings?.printSize?.value ?? "A4"}
            />
        </Document>
    );

    const downloadTemplate = () => {
        let printSize = props?.executorSettings?.printSize?.value ?? "A4";
        let link = `templates/QrCodeTemplate${printSize}.pdf`;
        window.open(link, "_blank")
    }

    return (
        <div>
            <SpinnerComponent open={isGeneratePressed} />
            <Button
                onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                }}
                id="btn_Menu-Print-Id-Codes"
                className="projectMenu-button"
            >
                <img style={{ height: 24, marginRight: "0.8em" }} src={"/pictures/qrImage.png"} />
                {t("QrCodeCreation")}
            </Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <ProjectQrCodePrint
                    open={printQrCodesOpen}
                    setOpen={setPrintQrCodesOpen}
                />
                <DialogTitle id="customized-dialog-title" >
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            {t("QrCodeCreation")}
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" size="small" onClick={() => handleClose()}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid column>
                        <Grid item>
                            <Typography gutterBottom>
                                {t("NumberOfQrCodes")}
                            </Typography>
                            <ButtonComponent
                                id="qrcode-template"
                                value={t('DownloadQrTemplate')}
                                color="primary"
                                className="button btn-no-left-margin"
                                onChange={downloadTemplate}
                            />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent t={t} model={element} onChange={e => updateModel(e.target.value)} style={{ marginBottom: "20px" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <ButtonComponent id="qrcode-generation" value={t('Generate')} color="primary" className="button" onChange={actionGenerate}></ButtonComponent>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {showPrintExistingCodes &&
                        <Grid column>
                            <Grid item>
                                <HeadingComponent value={t('GenerateQrCode')} size="h6" />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <ButtonComponent value={t('Cancel')} color="secondary" id="close-print-qr-codes" onChange={() => { setIsGeneratePressed(false); setOpen(false) }}></ButtonComponent>
                                    </Grid>
                                    <Grid item>
                                        <ButtonComponent value={t('Print')} color="primary" onChange={() => setPrintQrCodesOpen(true)} id="open-print-qr-codes"></ButtonComponent>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>}
                </DialogContent>
            </Dialog>
        </div>
    );
}